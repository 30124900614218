<template>
    <ds-organism-icon-link-bar
        v-if="!iconLinkBar.disable"
        :share-text="iconLinkBar.shareText ?? 'Share the article on:'"
        :items="items"
        :icon-background-color="iconLinkBar.iconBackgroundColor ?? null"
        :icon-color="iconLinkBar.iconColor ?? null"
    />
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

import { DsOrganismIconLinkBar } from 'design-system-component-library/marketplaces';

const page = usePage();
const iconLinkBar = page.props.settings.globalComponents.iconLinkBar;

const buildShareUrl = type => {
    return {
        facebook: 'https://www.facebook.com/sharer.php?u=' + encodeURI(page.props.url),
        twitter: 'https://twitter.com/share?text=' + encodeURI(page.props.pageSettings.editorTitle) + '&url=' + encodeURI(page.props.url),
        linkedin: 'https://www.linkedin.com/shareArticle?url=' + encodeURI(page.props.url) + '&mini=true&title=' + encodeURI(page.props.pageSettings.editorTitle),
        pinterest: 'https://pinterest.com/pin/create/button/?url=' + encodeURI(page.props.url),
    }[type];
};

const items = iconLinkBar.items.map(item => ({
    type: item.type,
    url: buildShareUrl(item.type),
}));
</script>
